/**
 * Created by john on 8/10/16.
 */
'use strict';

/* exported
intlPhoneDirective
 */

function intlPhoneDirective() {
 return {
   restrict: 'A',
   require: 'ngModel',
   scope: {
     intlCountry: '=?'
   },
   link: function(scope, iElement, iAttrs, ngModelCtrl) {
     // Trigger the Tel Input
     iElement.intlTelInput();
     if (scope.intlCountry) {
      iElement.intlTelInput('setCountry', scope.intlCountry);
     }
     iElement.on('countrychange', function(e, countryData) {
       var internationalNumber = iElement.intlTelInput('getNumber');
       //var nationalNumber = iElement.intlTelInput('getNumber', IntlTelInputUtils.numberFormat.NATIONAL);

       //If country model was passed as attribute
       scope.intlCountry = countryData.iso2.toUpperCase();
       var isValid = iElement.intlTelInput('isValidNumber');

       if (isValid) {
         ngModelCtrl.$setViewValue(internationalNumber);
       }
     });

     iElement.on('change', function() {
       var isValid = iElement.intlTelInput('isValidNumber');

       if (isValid) {
         ngModelCtrl.$setViewValue(iElement.intlTelInput('getNumber') );
       }
     });

     ngModelCtrl.$parsers.push(function(viewValue) {
       return viewValue;
     });

     ngModelCtrl.$formatters.push(function(modelValue) {
       // If it doesn't exist, init
       var model = modelValue || {number: ''};

       return model.number;
     });


     ngModelCtrl.$render = function() {
       if (ngModelCtrl.$modelValue) {
         iElement.intlTelInput('setNumber', ngModelCtrl.$modelValue);
       }
     };
   }
 };
}

/**
 * Created by john on 27/10/16.
 */
'use strict';

/* exported
tripScheduleDirective
 */

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function isDate(date) {
  return (date instanceof Date && !isNaN(date.valueOf()));
}

function tripScheduleDirective(moment) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/directives/trip-schedule.html',
    scope: {
      route: '=tripRoute',
      roundTrip: '=roundTrip'
    },
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      // Helpers for timezone
      function addDateMinutes(date, minutes) {
        date = new Date(date.getTime());
        date.setMinutes(date.getMinutes() + minutes);
        return date;
      }

      function getSpecificTimezoneOffset(date, timezone) {
        var locationTimezone = moment.tz(date, timezone).format('Z'); // +07:00

        return locationTimezone.replace(/:/g, '') * -6 / 10; // -420
      }

      function getEventsForDate(events, date) {
        var dow = date.getDay();
        var recurringEvents = events.filter(function(event) {
          return (event.dow && event.dow.indexOf(dow) > -1);
        });

        var singleEvents = events.filter(function(event) {
          return (!event.dow || !event.dow.length);
        })
          .filter(function(event) {
            return moment(event.start).isSame(moment(date), 'day');
          });

        return recurringEvents.concat(singleEvents);
      }

      function hasNoEventForDate(events, date) {
        return getEventsForDate(events, date).length === 0;
      }

      scope.outboundDatePopup = {
        opened: false
      };

      // 3rd Step (Reverse Array Order)
      //From Model to View: From Location Timezone (i.e Vietnam) to User's timezone
      // We saved the 2nd of January 00:00 in Vietnam timezone (+7)
      // The user lives in Israel timezone (+2) so we should change the date so the date picker will display the 2nd of January in Israel's timezone
      // If we move the date forward so it's the 2nd of January 5am in Vietnam it will be exactly the 2nd of January 00:00 Israel time ( +7 - +2 = +5)
      // So we need to add 5hours, i.e. 5 * 60 = 300 minutes
      // Vietnam timezone offset is -420 (you need to remove 420 minutes to get to UTC) and Israel timezone offset is -120 so
      // we just need to take Date + (Local timezone offset - Location Timezone offset) = Date + (-120 - -420) = Date + 300
      ngModelCtrl.$formatters.push(function(modelValue) {
        var outboundDate = (modelValue || {}).outboundDate;
        var inboundDate = (modelValue || {}).inboundDate;

        if (scope.route) {
          if (outboundDate) {
            var localTimezoneOffsetAtOutboundDate = outboundDate.getTimezoneOffset();
            var outboundLocationTimezoneOffset = getSpecificTimezoneOffset(outboundDate, scope.route.from.timezone);
            modelValue.outboundDate = addDateMinutes(outboundDate, (localTimezoneOffsetAtOutboundDate - outboundLocationTimezoneOffset));
          }

          if (inboundDate) {
            var localTimezoneOffsetAtInboundDate = inboundDate.getTimezoneOffset();
            var inboundLocationTimezoneOffset = getSpecificTimezoneOffset(inboundDate, scope.route.to.timezone);
            modelValue.inboundDate = addDateMinutes(inboundDate, (localTimezoneOffsetAtInboundDate - inboundLocationTimezoneOffset));
          }
        }

        return modelValue;
      });

      // 2nd Step (Reverse Array Order)
      // Check that date is a Date object
      ngModelCtrl.$formatters.push(function(modelValue) {
        var outboundDate = (modelValue || {}).outboundDate;
        var inboundDate = (modelValue || {}).inboundDate;

        if (outboundDate && !isDate(outboundDate)) {
          modelValue.outboundDate = new Date(modelValue.outboundDate);
        }

        if (inboundDate && !isDate(inboundDate)) {
          modelValue.inboundDate = new Date(modelValue.inboundDate);
        }

        return modelValue;
      });

      // 1st Step (Reverse Array Order)
      // From Model to View
      ngModelCtrl.$formatters.push(function(modelValue) {
        return {
          outboundDate: ((modelValue || {}).outboundTrip || {}).date,
          outboundEvent: ((modelValue || {}).outboundTrip || {}).event,

          inboundDate: ((modelValue || {}).inboundTrip || {}).date,
          inboundEvent: ((modelValue || {}).inboundTrip || {}).event
        };
      });

      ngModelCtrl.$render = function() {
        scope.outboundDate = ngModelCtrl.$viewValue.outboundDate ? new Date(ngModelCtrl.$viewValue.outboundDate) : ngModelCtrl.$viewValue.outboundDate;
        scope.outboundEvent = ngModelCtrl.$viewValue.outboundEvent;

        scope.inboundDate = ngModelCtrl.$viewValue.inboundDate ? new Date(ngModelCtrl.$viewValue.inboundDate) : ngModelCtrl.$viewValue.inboundDate;
        scope.inboundEvent = ngModelCtrl.$viewValue.inboundEvent;
      };

      // From View to model
      scope.$watch('inboundDate + outboundDate + inboundEvent + outboundEvent', function() {
        if (!scope.inboundDate && !scope.outboundDate && !scope.inboundEvent && !scope.outboundEvent) {
          return;
        }

        ngModelCtrl.$setViewValue({
          outboundDate: scope.outboundDate,
          outboundEvent: scope.outboundEvent,
          inboundDate: scope.inboundDate,
          inboundEvent: scope.inboundEvent
        });
      });

      ngModelCtrl.$parsers.push(function (viewValue) {
        var outboundDate = (viewValue || {}).outboundDate;
        var inboundDate = (viewValue || {}).inboundDate;

        if (scope.route) {
          if (outboundDate) {
            var localTimezoneOffsetAtOutboundDate = outboundDate.getTimezoneOffset();
            var outboundLocationTimezoneOffset = getSpecificTimezoneOffset(outboundDate, scope.route.from.timezone);
            viewValue.outboundDate = addDateMinutes(outboundDate, -1 * (localTimezoneOffsetAtOutboundDate - outboundLocationTimezoneOffset));
          }

          if (inboundDate) {
            var localTimezoneOffsetAtInboundDate = inboundDate.getTimezoneOffset();
            var inboundLocationTimezoneOffset = getSpecificTimezoneOffset(inboundDate, scope.route.to.timezone);
            viewValue.inboundDate = addDateMinutes(inboundDate, -1 * (localTimezoneOffsetAtInboundDate - inboundLocationTimezoneOffset));
          }
        }


        return viewValue;
      });

      ngModelCtrl.$parsers.push(function(viewValue) {
        var modelValue = {
          outboundTrip: {}
        };

        if (viewValue.outboundDate) {
          modelValue.outboundTrip.date = viewValue.outboundDate.toString();
        }

        if (viewValue.outboundEvent) {
          modelValue.outboundTrip.event = viewValue.outboundEvent;
          modelValue.outboundTrip.time = scope.getEventTime(modelValue.outboundTrip.event);
        }

        if (viewValue.inboundDate && scope.roundTrip) {
          modelValue.inboundTrip = {
            date: viewValue.inboundDate.toString()
          };
        }

        if (viewValue.inboundEvent && scope.roundTrip) {
          modelValue.inboundTrip.event = viewValue.inboundEvent;
          modelValue.inboundTrip.time = scope.getEventTime(modelValue.inboundTrip.event);
        }

        return modelValue;
      });




      /**
       * Manage Outbound Date
       */

      function disableOutboundDate(data) {
        var date = data.date;

        // Get the dates that have outbound trips
        var outboundEvents = (scope.route || {}).outboundEvents;
        return outboundEvents && outboundEvents.length ? hasNoEventForDate(outboundEvents, date) : true;
      }

      scope.openOutboundDate = function() {
        // Check that line has been selected before
        if (!scope.route) {
          scope.$emit('notify', {type: 'error', title: 'Error', message: 'Please select a Route before'});
          return;
        }

        scope.outboundDatePopup.opened = true;
      };

      scope.outboundDateOptions = {
        dateDisabled: disableOutboundDate,
        formatYear: 'yy',
        maxDate: new Date(2020, 5, 22),
        minDate: new Date(),
        startingDay: 1
      };

      scope.toggleOutboundMaxDate = function(date) {
        scope.outboundDateOptions.maxDate = date;
      };

      scope.$watch('outboundDate', function(newOutboundDate) {
        if (!scope.route || !newOutboundDate) {
          return;
        }

        // Update Outbound Min Date
        scope.toggleInboundMinDate(newOutboundDate);

        scope.outboundEvents = getEventsForDate(scope.route.outboundEvents, newOutboundDate);
      });

      /**
       * Manage Outbound Date
       */
      function disableInboundDate(data) {
        var date = data.date;
        var inboundEvents = (scope.route || {}).inboundEvents;

        return inboundEvents && inboundEvents.length ? hasNoEventForDate(inboundEvents, date) : true;
      }

      scope.inboundDatePopup = {
        opened: false
      };

      scope.openInboundDate = function() {
        // Check that line has been selected before
        if (!scope.route) {
          scope.$emit('notify', {type: 'error', title: 'Error', message: 'Please select a Route before'});
          return;
        }

        scope.inboundDatePopup.opened = true;
      };

      scope.inboundDateOptions = {
        dateDisabled: disableInboundDate,
        formatYear: 'yy',
        maxDate: new Date(2020, 5, 22),
        minDate: new Date(),
        startingDay: 1
      };

      scope.toggleInboundMinDate = function(date) {
        scope.inboundDateOptions.minDate = date;
      };

      scope.$watch('inboundDate', function(newInboundDate) {
        if (!scope.route || !newInboundDate) {
          return;
        }

        // Update Outbound Max Date
        scope.toggleOutboundMaxDate(newInboundDate);

        scope.inboundEvents = getEventsForDate(scope.route.inboundEvents, newInboundDate);
      });

      // Watch route is empty to reset
      scope.$watch('route', function(route) {
        if (!route) {
          scope.outboundDate = null;
          scope.outboundEvent = null;

          scope.inboundDate = null;
          scope.inboundEvent = null;
        }
      });

      /**
       * Used in template
       */
      scope.getEventTime = function(event) {
        var startDate = moment(event.start);
        var startDuration = moment.duration(event.start);
        var startFormat;

        if (startDate.isValid()) {
          startFormat = startDate.format('HH:mm');
        } else if (moment.isDuration(startDuration)) {
          startFormat = pad(startDuration.get('hours'), 2, 0) + ':' + pad(startDuration.get('minutes'), 2, 0);
        } else {
          startFormat = null;
        }

        return startFormat;
      };
    }
  };
}

/**
 * Created by john on 28/10/16.
 */
'use strict';

/* exported
extraOptionsDirective
 */

function extraOptionsDirective() {
  return {
    restrict: 'AE',
    require: ['ngModel', '^form'],
    templateUrl: 'views/directives/extra-options.html',
    scope: {
      extraOptionDefinitions: '<',
      passengerIndex: '@',
      name: '@'
    },
    link: function(scope, iElement, iAttrs, ctrls) {
      var ngModelCtrl = ctrls[0];
      scope.parentForm = ctrls[1];

      if (scope.name && scope.parentForm) {
        scope.$watch(function() {
          return scope.parentForm[scope.name].$touched;
        }, function(isTouched) {
          if (isTouched) {
            for (var i = 0; i < scope.extraOptionDefinitions.length; i++) {
              var extraOptionDefinition = scope.extraOptionDefinitions[i];

              if (scope.parentForm[extraOptionDefinition._id]) {
                scope.parentForm[extraOptionDefinition._id].$setTouched();
              }
            }
          }
        });
      }

      scope.extraOptionFormElements = {};

      scope.getCss = function(extraOptionDefinition) {
        return extraOptionDefinition.icon.provider + ' ' + extraOptionDefinition.icon.cssClass;
      };

      // From Model to View
      ngModelCtrl.$formatters.push(function(modelValue) {
        return modelValue || [];
      });

      ngModelCtrl.$render = function () {
        Object.keys(scope.extraOptionFormElements).forEach(function (key) {
          scope.extraOptionFormElements[key].selected = false;
        });
        ngModelCtrl.$viewValue.forEach(function (extraOption) {
          scope.extraOptionFormElements[extraOption.definition] = {
            selected: true,
            value: extraOption.value
          };
        });
      };

      // From View to model
      scope.$watch('extraOptionFormElements', function(extraOptionFormElements) {
        if (!extraOptionFormElements) {
          ngModelCtrl.$setViewValue([]);
          return;
        }

        var viewValue = [];

        Object.keys(extraOptionFormElements).map(function(extraOptionDefinitionId) {
          var extraOptionFormElement = extraOptionFormElements[extraOptionDefinitionId];
          var extraOptionDefinition =  _.find(scope.extraOptionDefinitions, function(extraOptionDefinition) {
            return extraOptionDefinition._id === extraOptionDefinitionId;
          });

          if (extraOptionFormElement.selected) {
            viewValue.push({
              definition: extraOptionDefinitionId,
              value: extraOptionFormElement.value,
              perBooking: extraOptionDefinition.perBooking
            });
          }
        });

        ngModelCtrl.$setViewValue(viewValue);
      }, true);

      scope.$watch('extraOptionDefinitions', function(extraOptionDefinitions) {
        if (!extraOptionDefinitions) {
          ngModelCtrl.$setViewValue([]);
        }
      });

      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue;
      });
    }
  };
}

/**
 * Created by john on 28/10/16.
 */
"use strict";

/* exported
inputPassengerExtraInfoDirective
 */

function inputPassengerExtraInfoDirective() {
  return {
    restrict: "AE",
    scope: {
      passengerExtraInfoDefinitions: "<",
      passengerIndex: "@",
      isB2b: "=",
    },
    templateUrl: "views/directives/input-passenger-extra-info.html",
    require: ["ngModel", "^form"],
    link: function (scope, iElement, iAttrs, ctrls) {
      var ngModelCtrl = ctrls[0];
      scope.parentForm = ctrls[1];
      scope.extraInfoFormElements = {};

      var dateAttrs = ["date", "dateBefore", "dateAfter"];

      // From Model to View
      ngModelCtrl.$formatters.push(function (modelValue) {
        var formatedModelValue = [];
        (modelValue || []).forEach(function (extraInfo){
          var type = scope.passengerExtraInfoDefinitions.find((d) => d._id === extraInfo.definition).type;
          formatedModelValue.push({
            definition: extraInfo.definition,
            _id: extraInfo._id,
            value: dateAttrs.includes(type) ? new Date(extraInfo.value) : extraInfo.value,
          });
        });
        return formatedModelValue || [];
      });

      ngModelCtrl.$render = function () {
        ngModelCtrl.$viewValue.forEach(function (extraInfo) {
          scope.extraInfoFormElements[
            extraInfo.definition._id || extraInfo.definition
          ] = extraInfo.value;
        });
      };

      // From view to model
      scope.$watch(
        "extraInfoFormElements",
        function (extraInfoFormElements) {
          if (!_.size(extraInfoFormElements)) {
            return;
          }

          var viewValue = [];

          for (var key in extraInfoFormElements) {
            var extraInfoDefinition = scope.passengerExtraInfoDefinitions.find(
              (d) => d._id === key
            );
            if(extraInfoDefinition) {
              var type = extraInfoDefinition.type;
              viewValue.push({
                definition: key,
                value: dateAttrs.includes(type)
                  ? moment(extraInfoFormElements[key]).format("YYYY-MM-DD")
                  : extraInfoFormElements[key],
              });
            }
          }

          ngModelCtrl.$setViewValue(viewValue);
        },
        true
      );
    },
  };
}

/**
 * Created by john on 30/10/16.
 */
'use strict';

/* exported
tickCalendarDirective
 */

function tickCalendarDirective($uibModal, moment, uuid, $timeout, $compile, $confirm, InventoryRestangular, LineEditService, PrivateTransferService) {
  return {
    restrict: 'A',
    scope: {
      events: '=ngModel',
      direction: '=direction',
      trips: '=trips',
      updatedTrips: '=updatedTrips',
      calendarId: '@tickCalendar',
      setCalendarMethods: '&setTickCalMethods',
      timezone: '=tickCalTimezone',
      newBlockEvents: '=newBlockEvents',
      user: '=user',
      lineId: '=lineId',
      transferId: '=',
      fromCity: '=fromCity',
      toCity: '=toCity',
      isPrivateTransfer: '=isPrivateTransfer',
      privateTransferDuration: '=privateTransferDuration',
      deletedEventsIds: '=deletedEventsIds',
      multiStations: '=multiStations',
    },
    templateUrl: 'views/directives/tick-calendar.html',
    link: function(scope, iElement) {

      scope.tripsForEvents = {};

      function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

      function getId() {
        return uuid.v4();
      }

      function getBlockEvent(event, day) {
        return {
          day: day,
          ms: event.start._milliseconds,
          direction: scope.direction,
        };
      }

      function addNewBlockEvent(event, day) {
        scope.newBlockEvents.push(getBlockEvent(event, day));
      }

      function removeNewBlockEvent(event, day) {
        scope.newBlockEvents = scope.newBlockEvents.filter(function(item) {
          return !_.isEqual(item, getBlockEvent(event, day));
        });
      }

      function isTripInEventDuration(trip, evenStart, evenEnd) {
        var tripDepartureDate = new Date(moment.tz(trip.departure.date, scope.timezone).format('YYYY-MM-DD HH:mm:ss'));
        var tripArrivalDate = new Date(moment.tz(trip.arrival.date, scope.timezone).format('YYYY-MM-DD HH:mm:ss'));
        var eventStartDate = new Date(moment.tz(evenStart, scope.timezone).format('YYYY-MM-DD HH:mm:ss'));
        var eventEndDate;

        if (scope.isPrivateTransfer) {
          eventEndDate = new Date(moment.tz(evenStart, scope.timezone).add(scope.privateTransferDuration, 'hours').format('YYYY-MM-DD HH:mm:ss'));
        }
        else {
          eventEndDate = new Date(moment.tz(evenEnd, scope.timezone).format('YYYY-MM-DD HH:mm:ss'));
        }

        return (tripDepartureDate >= eventStartDate) && (tripArrivalDate <= eventEndDate);
      }

      function getExistingTripForEvent(event, evenStartDate, evenEndDate) {
        var trip = _.find(scope.trips, function (trp) {
          return (trp.eventId === event._id) && isTripInEventDuration(trp, evenStartDate, evenEndDate);
        });

        return trip;
      }

      function getAllocationDefinitionForEvent(event, evenStartDate) {
        var allocationDefinition = _.find(event.allocations || [], function(ad) {
          var allocationDefinitionStart = moment(ad.startDate).startOf('day');
          var allocationDefinitionEnd = moment(ad.endDate).endOf('day');
          return (moment(evenStartDate) >= allocationDefinitionStart) && (moment(evenStartDate) <= allocationDefinitionEnd);
        });

        return allocationDefinition;
      }

      function createTripForEvent(event, evenStartDate, evenEndDate) {
        var trip;
        var existingTrip = getExistingTripForEvent(event, evenStartDate, evenEndDate);

        if (existingTrip) {
          trip = angular.copy(existingTrip);
        }
        else {
          var allocationDefinition = getAllocationDefinitionForEvent(event, evenStartDate);

          if (allocationDefinition) {
            trip = {};
            trip.allocationDefinition = allocationDefinition;
            trip.allocatedSeats = trip.allocationDefinition.allocatedSeats;
            trip.seatsBooked = 0;
          }
        }

        if (!trip) {
          trip = {seatsBooked: 0};
        }

        return trip;
      }

      function setTripForEvent(event, eventStartDate, trip) {
        scope.tripsForEvents[event.id + '/' + eventStartDate] = trip;
      }

      function getTripForEvent(event, eventStartDate) {
        var trip = scope.tripsForEvents[event.id + '/' + eventStartDate];
        return trip;
      }

      function eventSource(start, end, timezone, callback) {
        var events = (scope.events || []).map(function(event) {

          //When timezone changes
          if (timezone !== event.currentTimezone) {
            event.currentTimezone = timezone;
          }

          // Single Event
          if (!event.dow || event.dow.length === 0) {
            if (event.dow) {
              delete event.dow;
            }

            if (typeof event.start === 'string') {
              event.start = moment(event.start).tz(timezone);
            }

            if (typeof event.end === 'string') {
              event.end = moment(event.end).tz(timezone);
            }
          }
          else { // Recurring Event

            if (typeof event.start === 'string') {
              event.start = moment.duration(event.start);
            }

            if (typeof event.end === 'string') {
              event.end = moment.duration(event.end);
            }
          }
          return event;
        });

              callback(events);
      }

      if (scope.setCalendarMethods) {
        scope.setCalendarMethods({methods: {
          refresh: function() {
            $timeout()
              .then(function() {
                iElement.find('#' + scope.calendarId).fullCalendar( 'render');
              });
          }
        }});
      }

      scope.eventResize = function(event, delta) {
        var originalEvent = _.find(scope.events, function(originalEvent) {
          return originalEvent.id === event.id;
        });

        scope.$apply(function() {
          // end is either a moment or duration so we can use add
          originalEvent.end.add(delta.as('minutes'), 'minutes');
          iElement.find('#' + scope.calendarId).fullCalendar( 'refetchEvents');
        });
      };

      scope.eventDrop = function(event, delta) {
        var originalEvent = _.find(scope.events, function(originalEvent) {
          return originalEvent.id === event.id;
        });

        scope.$apply(function() {

          if (event.end) {
            originalEvent.end.add(delta.as('minutes'), 'minutes');
          } else {
            originalEvent.allDay = event.allDay;
          }

          originalEvent.start.add(delta.as('minutes'), 'minutes');
          iElement.find('#' + scope.calendarId).fullCalendar( 'refetchEvents');
        });
      };

      function adjustCrossDayEvent(event){
        if (event.start >= event.end) {
          event.end = event.end.add(moment.duration({hours: 24}));
        }
      }

      function adjustRecurringEventAfterUpdate(originalEvent, updatedEvent){
        originalEvent.dow = updatedEvent.dow;
        originalEvent.priceType = updatedEvent.priceType;
        originalEvent.price = updatedEvent.price;

        // In case the trip happens during the night we need to add 24H
        adjustCrossDayEvent(originalEvent);
      }

      function getSecondPrimary(eventMultiStations  = [], multiStations = []){
        var selectedMultiStations = eventMultiStations && eventMultiStations.length ? eventMultiStations : multiStations;
        return selectedMultiStations.find(function( stationT ){ return stationT.isPrimary && stationT.timeOffsetInMinutes > 0; });
      }

      scope.eventClick = function(event) {
        if (scope.user && scope.user.shouldSeeFullAdmin) {
          var modalInstance = $uibModal.open({
            templateUrl: 'views/event/edit.html',
            controller: 'EventEditCtrl',
            size: 'md',
            resolve: {
              event: function () {
                var copiedEvent = angular.copy(event);

                copiedEvent.currentTimezone = scope.timezone;

                delete copiedEvent.source;

                return copiedEvent;
              },
              lineEditService: ['LineEditService', function(LineEditService) {
                return LineEditService;
              }],
              multiStations: ['LineEditService', function(LineEditService){
                if (scope.direction === 'aToB'){
                  return _.cloneDeep(LineEditService.line.aToBMultiStations);
                } else {
                  return _.cloneDeep(LineEditService.line.bToAMultiStations);
                }
              }],
              fromStation: ['LineEditService', function(LineEditService){
                if (scope.direction === 'aToB'){
                  return _.cloneDeep(LineEditService.line.stationA);
                } else {
                  return _.cloneDeep(LineEditService.line.stationB);
                }
              }],
              toStation: ['LineEditService', function(LineEditService){
                if (scope.direction === 'aToB'){
                  return _.cloneDeep(LineEditService.line.stationB);
                } else {
                  return _.cloneDeep(LineEditService.line.stationA);
                }
              }]
            },
            backdrop: 'static',
            keyboard: false
          });

          modalInstance.result.then(function (updatedEvent) {
            var originalEvent = _.find(scope.events, function (event) {
              return event.id === updatedEvent.id;
            });

            originalEvent.title = updatedEvent.title;
            originalEvent.description = updatedEvent.description;
            originalEvent.color = updatedEvent.color;
            if (updatedEvent.allocations) {
              originalEvent.allocations = updatedEvent.allocations || [];
            }

            if (updatedEvent.datesRange) {
              originalEvent.datesRange = updatedEvent.datesRange || [];
            }

            if (updatedEvent.multiStations) {
              originalEvent.multiStations = updatedEvent.multiStations || [];
            }

            const foundStation = getSecondPrimary(originalEvent.multiStations, scope.multiStations);

            // recurring event
            if (updatedEvent.dow && updatedEvent.dow.length) {
              originalEvent.start = moment.duration(moment(updatedEvent.start).format('HH:mm'));
              originalEvent.end = moment.duration(moment(updatedEvent.start).add(foundStation.timeOffsetInMinutes, 'minutes').format('HH:mm'));
              adjustRecurringEventAfterUpdate(originalEvent, updatedEvent);

              if (scope.isPrivateTransfer) {
                const multiStations = originalEvent.multiStations && originalEvent.multiStations.length ?
                  originalEvent.multiStations :
                  _.cloneDeep(scope.direction === 'aToB' ? LineEditService.line.aToBMultiStations : LineEditService.line.bToAMultiStations);
                PrivateTransferService.splitCrossDayEvent(originalEvent, multiStations, function (newEvent) {
                  scope.events.push(newEvent);
                  iElement.find('#' + scope.calendarId).fullCalendar('refetchEvents');
                });
                PrivateTransferService.splitPriceRules();
              }
            } else {
              // single event
              originalEvent.start = moment.tz(updatedEvent.start.format(), updatedEvent.currentTimezone);
              originalEvent.end = moment.tz(updatedEvent.start,updatedEvent.currentTimezone).add(foundStation.timeOffsetInMinutes, 'minutes');

              // In case the trip happens during the night we need to add 24H
              adjustCrossDayEvent(originalEvent);

              delete originalEvent.dow;
            }
            iElement.find('#' + scope.calendarId).fullCalendar('refetchEvents');
          }, function () {

          });
        }
      };

      scope.delete = function(eventId, day, eventStartDate) {
        var event =  _.find(scope.events, function(originalEvent) {
          return originalEvent.id === eventId;
        });
        var trip = getTripForEvent(event, eventStartDate);

          var modalInstance = $uibModal.open({
            // different modals for recurring events and single events
            templateUrl: (event.dow && event.dow.length) ? 'views/event/delete.html' : 'views/event/delete-specific.html',
            controller:'EventDeleteCtrl',
            resolve: {
              event: function() {
                var copiedEvent = angular.copy(event);

                delete copiedEvent.source;

                copiedEvent.currentTimezone = scope.timezone;

                return copiedEvent;
              },
              day: function () {
                return day;
              },
              newEvents: function() {
                return {
                  add: addNewBlockEvent,
                  remove: removeNewBlockEvent
                };
              },
              lineId: function() {
                return scope.lineId;
              },
              user: scope.user,
              trip: trip,
              fromCity: function() {
                return scope.fromCity;
              },
              toCity: function() {
                return scope.toCity;
              },
              updatedTrips: function() {
                return scope.updatedTrips;
              },
              isPrivateTransfer: function() {
                return scope.isPrivateTransfer;
              },
              direction: function(){
                if (scope.calendarId === 'inbound-schedule'){
                  return 'aToB';
                } else {
                  return 'bToA';
                }
              }
            }
          });

          modalInstance.result.then(function (resultObj) {
            if (resultObj.action === 'delete') {
              scope.events = scope.events.filter(function(event) {
                if (event.id === eventId && event._id){
                  scope.deletedEventsIds.push(event._id);
                }
                return event.id !== eventId;
              });

              iElement.find('#' + scope.calendarId).fullCalendar( 'removeEvents', function(event) {
                return event.id === eventId;
              });


            } else if (resultObj.action === 'updatedEvent') {
              var updatedEvent = resultObj.data;
              var originalEvent = _.find(scope.events, function(event) {
                return event.id === updatedEvent.id;
              });

              originalEvent.dow = updatedEvent.dow;

              if (originalEvent.full || updatedEvent.full) {
                originalEvent.full = updatedEvent.full;
              }

              if (updatedEvent.duplicationActivated && updatedEvent.duplicationStart && updatedEvent.duplicationEnd && updatedEvent.duplicationInterval && !scope.isPrivateTransfer) {
                var nextStart = moment(updatedEvent.duplicationStart);
                var endDuplication = moment(updatedEvent.duplicationEnd).add(1, 'milliseconds');
                var tempEvent;
                while(nextStart.isBefore(endDuplication)){
                  tempEvent = scope.creteFormattedEvent(nextStart.clone(), nextStart.clone().add(updatedEvent.duplicationDuration, 'minutes'));
                  tempEvent.start = moment.duration(moment.tz(tempEvent.start, updatedEvent.currentTimezone).format('HH:mm'));
                  tempEvent.end = moment.duration(moment.tz(tempEvent.end, updatedEvent.currentTimezone).format('HH:mm'));
                  adjustRecurringEventAfterUpdate(tempEvent, updatedEvent);
                  tempEvent.color = getRandomColor();
                  if (updatedEvent.datesRange){
                    tempEvent.datesRange = updatedEvent.datesRange;
                  }
                  delete tempEvent._id;
                  if (updatedEvent.multiStations && updatedEvent.multiStations.length){
                    tempEvent.multiStations = _.cloneDeep(updatedEvent.multiStations);
                  }
                  scope.events.push(tempEvent);
                  nextStart = nextStart.clone().add(moment.duration(updatedEvent.duplicationInterval, 'minutes'));
                }
              }
            } else if (resultObj.action === 'updatedTrip'){
              const existingTrip = resultObj.data.trip;
              var eventToChange = _.find(scope.events, function(event) {
                return event.id === resultObj.data.event.id;
              });

              if (existingTrip && existingTrip.multiStations && existingTrip.multiStations.length) {
                // recurring event
                if (eventToChange.dow && eventToChange.dow.length) {
                  //do nothing - recurring event can't have single time
                } else {
                  // single event
                  const foundStation = getSecondPrimary(existingTrip.multiStations, scope.multiStations);
                  eventToChange.end = moment.tz(event.start, eventToChange.currentTimezone).add(foundStation.timeOffsetInMinutes, 'minutes');
                }
              }
            }

            iElement.find('#' + scope.calendarId).fullCalendar( 'refetchEvents');
          });
      };

      scope.creteFormattedEvent = function (startDate, endDate) {
        return {
          title: 'From A to B',
          start: startDate.format(),
          end: endDate.format(),
          stick: true,
          className: 'newtask',
          color: '#367fa9',
          id: getId(),
          timezone: scope.timezone || 'UTC',
          full: []
        };
      };

      scope.select = function(start, end, jsEvent) {

        if (jsEvent.target.dataset && jsEvent.target.dataset.name === 'toggleFullyBooked') {
          jsEvent.preventDefault();
          return false;
        }

        var foundStation = getSecondPrimary([], scope.multiStations);

        var startDate = moment.tz(start.format(), scope.timezone);
        var endDate = moment.tz(startDate.clone().add(foundStation.timeOffsetInMinutes, 'minutes').format(), scope.timezone);

        var event = scope.creteFormattedEvent(startDate, endDate);

        scope.$apply(function() {
          scope.events.push(event);
          iElement.find('#' + scope.calendarId).fullCalendar( 'refetchEvents');
        });
      };

      scope.toogleWholeDayBooked = function(date) {
        var momentDate = moment(new Date(date));
        var dayOfWeek = parseInt(momentDate.format('e'));

        var events = scope.events;

        var day = momentDate.format('YYYY-MM-DD');

        if (!events.length) {
          return;
        }

        var wasBlocked;

        events.forEach(function (event) {
          if ((event.dow && event.dow.indexOf(dayOfWeek)) > -1 || (((event.dow && event.dow.length === 0) || (!event.dow)) && moment(event.start).format('YYYY-MM-DD') === day)) {
            if (wasBlocked === undefined){
              wasBlocked = (event.full && event.full.indexOf(day) > -1);
            }
            if (!wasBlocked) {
              if (event.full && event.full.indexOf(day) > -1) {
                // do nothing because event already fully booked
              } else {
                event.full.push(day);
                addNewBlockEvent(event, day);
              }
            } else {
              var index = event.full.indexOf(day);

              if (index > -1) {
                event.full.splice(index, 1);
              }

              removeNewBlockEvent(event, day);
            }
          }
        });

        // Force re-render of calendar
        iElement.find('#' + scope.calendarId).fullCalendar( 'refetchEvents');

        return false;
      };

      function getTripPriceType(tripPrice) {
        switch(tripPrice.priceType) {
          case 'manual':
            return 'Trip';
          case 'exception':
            return 'Exception';
          case 'period':
            return 'Departure';
          case 'default':
            return 'Line';
        }
      }

      function checkIfDateInRangeOrNoRange(departureDate, range, timezone ){
        return !range || range.length === 0 || (range || []).some(function(item){
          const startRange = moment.tz(item.startDate, timezone).startOf('day');
          const endRange = item.endDate ? moment.tz(item.endDate, timezone).endOf('day') : undefined;
          return (departureDate.isSame(startRange) || departureDate.isAfter(startRange) ) &&
            (!item.endDate || departureDate.isSame(endRange) || departureDate.isBefore(endRange) );
        });
      }

      scope.options = {
        header: {
          left: 'month agendaWeek agendaDay',
          center: 'title',
          right: 'today prev,next'
        },
        defaultView: 'agendaWeek',
        height: 650,
        editable: false,
        droppable: false,
        eventResourceEditable: true,
        events: eventSource,
        eventClick: scope.eventClick,
        eventResize: scope.eventResize,
        eventDrop: scope.eventDrop,
        timezone: scope.timezone || 'UTC',
        selectable: true,
        select: scope.select,
        firstDay: 1,
        timeFormat: 'HH:mm',
        eventMouseover: function(calEvent, jsEvent) {
          var tooltip = '<div class="tooltipevent" style="width:200px;height:200px;background:'+ calEvent.color + ';position:absolute;z-index:10001;border-radius:8px;color:white">' +
            calEvent.toolTipDataHtml.join("<br>") +
            '</div>';
          var $tooltip = $(tooltip).appendTo('body');

          $(this).mouseover(function(e) {
            $tooltip.fadeIn('500');
            $tooltip.fadeTo('10', 1.9);
            $tooltip.css('top', e.pageY - 110);
            $tooltip.css('left', e.pageX + 20);
          })
            .mousemove(function(e) {
            $tooltip.css('top', e.pageY - 110);
            $tooltip.css('left', e.pageX + 20);
          });
        },

        eventMouseout: function(calEvent, jsEvent) {
          $('.tooltipevent').remove();
        },
        eventRender: function(event, el) {
          el.find('.fc-title').hide();
            event.toolTipDataHtml = [event.start.clone().format("HH:mm") + '-' + event.end.clone().format("HH:mm")];


            var day = moment(event.start).format('YYYY-MM-DD');
            var isFullyBooked = false;

            if (event.full) {
              isFullyBooked = _.find(event.full, function (element) {
                return element === day;
              });
            }

            var eventStartDate = event.start.clone().format();
            var evenEndDate = event.end.clone().format();

            var trip = getTripForEvent(event, eventStartDate);

            if (!trip) {
              trip = createTripForEvent(event, eventStartDate, evenEndDate);
              setTripForEvent(event, eventStartDate, trip);
            }

            var closeHtml = '<span class="close-event" ng-click="delete(\'' + event.id + '\',\'' + day + '\',\'' + eventStartDate + '\');$event.stopPropagation()"><i class="fa-ellipsis-h fa"></i></span>';
            var closeCompiledHtml = $compile(closeHtml)(scope);
            el.append(closeCompiledHtml);

            if (isFullyBooked) {
              el.append(
                angular.element('<div class="fully-booked">').text('Fully Booked')
              );
              event.toolTipDataHtml.push('Fully Booked');
            }

            if (trip.isFullyAllocated) {
              el.append(
                angular.element('<div class="fully-booked">').text('Fully Allocated')
              );
              event.toolTipDataHtml.push('Fully Allocated');
            }

            if (scope.user && scope.user.shouldSeeFullAdmin) {
              var isAllocatedTripDefined = trip.allocatedSeats !== undefined && trip.allocatedSeats !== null;
              var allocationAvailableForTrip = isAllocatedTripDefined || trip.seatsBooked;
              var editHtml = '';
              if (allocationAvailableForTrip) {
                var allocString = 'Alloc-appr: ' + trip.seatsBooked + '/' + (isAllocatedTripDefined ? trip.allocatedSeats : '--');
                event.toolTipDataHtml.push(allocString);
                if (trip.isManuallyAllocated) {
                  editHtml += '<div class="calendar-event-allocation is-manually-allocated-trip">' + allocString + '</div>';
                } else {
                  editHtml += '<div class="calendar-event-allocation">' + allocString + '</div>';
                }
              }

              if (trip.normalSeatsBooked) {
                var approvedString = 'Appr: ' + trip.normalSeatsBooked;
                editHtml += '<div>' + approvedString + '</div>';
                event.toolTipDataHtml.push(approvedString);
              }

              if (trip.pendingApprovalSeatsBooked) {
                var pendingString = 'Pend: ' + trip.pendingApprovalSeatsBooked;
                editHtml += '<div>' + pendingString + '</div>';
                event.toolTipDataHtml.push(pendingString);
              }

              if (trip.automaticApprovalBooked) {
                var automaticApprovalString = 'Auto-appr: ' + trip.automaticApprovalBooked;
                editHtml += '<div>' + automaticApprovalString + '</div>';
                event.toolTipDataHtml.push(automaticApprovalString);
              }

              if (trip.supplierApiSeatsBooked) {
                var supplierApiSeatsBookedString = 'Api-appr: ' + trip.supplierApiSeatsBooked;
                editHtml += '<div>' + supplierApiSeatsBookedString + '</div>';
                event.toolTipDataHtml.push(supplierApiSeatsBookedString);
              }
              if (trip && trip.price) {
                var tripPriceType = getTripPriceType(trip.price);

                if (tripPriceType && LineEditService.line.isSupplierApi) {
                  var priceString = 'Price: (' + tripPriceType + ') ' + trip.price.amount + '$, ' + trip.price.amountInSupplierCurrency + ' ' + trip.price.supplierCurrency;
                  event.toolTipDataHtml.push(priceString);

                  var costString = 'Cost: (' + tripPriceType + ') ' + trip.price.cost + '$, ' + trip.price.costInSupplierCurrency + ' ' + trip.price.supplierCurrency;
                  event.toolTipDataHtml.push(costString);

                if (LineEditService.line.priceWithVehicle && trip.priceWithVehicle){
                    var priceString = 'Price: (' + tripPriceType + ' with car) ' + trip.priceWithVehicle.amount + '$, ' + trip.priceWithVehicle.amountInSupplierCurrency + ' ' + trip.priceWithVehicle.supplierCurrency;
                    event.toolTipDataHtml.push(priceString);

                    var costString = 'Cost: (' + tripPriceType + ' with car) ' + trip.priceWithVehicle.cost + '$, ' + trip.priceWithVehicle.costInSupplierCurrency + ' ' + trip.priceWithVehicle.supplierCurrency;
                    event.toolTipDataHtml.push(costString);
                }

                } else {
                  let fromStationsArray = [];
                  let toStationsArray = [];
                  const multiStations =
                    (event.multiStations && event.multiStations.length && event.multiStations) ||
                    (trip.multiStations && trip.multiStations.length && trip.multiStations);
                  if (multiStations && multiStations.length) {
                    fromStationsArray = multiStations
                      .filter((st) => !st.isDropoff)
                      .map((st) => st.stationId);
                    toStationsArray = multiStations
                      .filter((st) => st.isDropoff)
                      .map((st) => st.stationId);
                  } else {
                    fromStationsArray = [trip.from];
                    toStationsArray = [trip.to];
                  }

                  const tripDate = moment.tz(trip.departure.date, trip.departure.timezone);
                  let tripPriceRules = LineEditService.refreshPriceRulesOnLocalLevel(tripDate, fromStationsArray, toStationsArray);

                  if (!tripPriceRules || tripPriceRules.length === 0) {
                    const price = LineEditService.line.defaultPrice;
                    var priceString = `Price (Default):  ${price.amount} $, ${price.amountInSupplierCurrency} ${price.supplierCurrency}`;
                    event.toolTipDataHtml.push(priceString);

                    var costString = `Cost (Default): ${price.cost} $, ${price.costInSupplierCurrency} ${price.supplierCurrency}`;
                    event.toolTipDataHtml.push(costString);
                  } else {
                    const rulesWithPassengerType = tripPriceRules.filter((rule) => !!(rule.passengerTypes && rule.passengerTypes.length));
                    const rulesWithoutPassengerType = tripPriceRules.filter((rule) => !(rule.passengerTypes && rule.passengerTypes.length));

                    let restRulesNum = rulesWithoutPassengerType.length;
                    if (rulesWithPassengerType.length) {
                      rulesWithPassengerType.forEach(rule => {
                        rule.passengerTypes.forEach((type => {
                          const pt = (LineEditService.line.company.passengerTypes || []).find((t) => t.id === type.id);

                          if (pt) {
                            var priceString = 'Price: (' + pt.type + ') ' + rule.price.amount + '$, ' + rule.price.amountInSupplierCurrency + ' ' + rule.price.supplierCurrency;
                            event.toolTipDataHtml.push(priceString);

                            var costString = 'Cost: (' + pt.type + ') ' + rule.price.costInSupplierCurrency + ' ' + rule.price.supplierCurrency;
                            event.toolTipDataHtml.push(costString);
                          }
                        }))
                      })
                    } else {
                      restRulesNum = rulesWithoutPassengerType.length - 1;
                      const price = tripPriceRules[0].price;
                      var priceString = 'Price: (Rule) ' + price.amount + '$, ' + price.amountInSupplierCurrency + ' ' + price.supplierCurrency;
                      event.toolTipDataHtml.push(priceString);

                      var costString = `Cost (Rule): ${price.cost} $, ${price.costInSupplierCurrency} ${price.supplierCurrency}`;
                      event.toolTipDataHtml.push(costString);
                    }
                    if (restRulesNum) {
                      event.toolTipDataHtml.push(`+ ${restRulesNum} more rule${restRulesNum === 1 ? '' : 's'}`);
                    }
                  }
                }
              }
              var editCompiledHtml = $compile(editHtml)(scope);
              el.append(editCompiledHtml);
            }


            if (trip.isSupplierApi) {
              el.append(
                angular.element('<div>').text('API')
              );
              event.toolTipDataHtml.push('API');
            }

            // this is a hack to support event range - fullcalendar version 3 does not support it. but if renderEvents return false, the event won't be renderd to view :)
            return checkIfDateInRangeOrNoRange(event.start, event.datesRange);
        },
        dayRender: function(date, cell) {
          var button = '<button data-name="toggleFullyBooked" class="tick-btn tick-btn-red" ng-click="toogleWholeDayBooked(' + date + ');">Toggle Fully Booked</button>';
          var compiledButton = $compile(button)(scope);
          angular.element(cell).append(compiledButton);
        }
      };

      $timeout()
        .then(function() {
          iElement.find('#' + scope.calendarId).fullCalendar(scope.options);
        });

      // When timezone changes update
      scope.$watch('timezone', function(timezone) {
        if (!timezone || !scope.options) {
          return;
        }

        $timeout()
          .then(function() {
            scope.options.timezone = timezone;
            iElement.find('#' + scope.calendarId).fullCalendar('option', 'timezone', timezone);
            iElement.find('#' + scope.calendarId).fullCalendar( 'refetchEvents');
          });
      });

      scope.$on('multi-stations-changed', function() {
        $timeout()
          .then(function(){
            scope.events.forEach(function(event){
              var foundTrip = getExistingTripForEvent(event, event.start, event.end);
              var foundStations;
              if (foundTrip) {
                foundStations = getSecondPrimary(foundTrip.multiStations, event.multiStations);
              }
              if (!foundStations) {
                foundStations = getSecondPrimary(event.multiStations, scope.multiStations);
              }
              if (event.dow && event.dow.length){
                event.end = moment.duration(event.start).add(foundStations.timeOffsetInMinutes, 'minutes');
              } else {
                event.end = moment(event.start).add(foundStations.timeOffsetInMinutes, 'minutes');
              }
            });
            $('#' + scope.calendarId).fullCalendar( 'refetchEvents');
            // not sure why, but its working but calling renderView after refetchEvents solve the rendering problem.
            $('#' + scope.calendarId).fullCalendar( 'option', 'renderView', '1');
            // iElement.find('#' + scope.calendarId).fullCalendar( 'render');
          });
      });

    }
  };
}

/**
 * Created by john on 5/11/16.
 */
'use strict';

/* exported
tickTzTimepicker
 */

function tickTzTimepicker(moment) {
  return {
    restrict: 'AE',
    template: '<div uib-timepicker ng-model="time" hour-step="1" minute-step="5" show-spinners="false" readonly-input="disabled" arrowkeys="!disabled"></div>',
    require: 'ngModel',
    scope: {
      tickTimezone: '=',
      disabled: '@'
    },
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      ngModelCtrl.$formatters.push(function(modelValue) {
        if (moment.isMoment(modelValue)) {
          var zoneDiffInMinutes = modelValue.utcOffset();
          var date = modelValue.toDate();
          var tzDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000) + (zoneDiffInMinutes * 60000));
          return tzDate;
        } else {
          return null;
        }
      });

      ngModelCtrl.$render = function() {
        scope.time = ngModelCtrl.$viewValue;
      };

      scope.$watch('time', function(time) {
        ngModelCtrl.$setViewValue(time);
      });

      ngModelCtrl.$parsers.push(function (value) {
        if (value) {
          var dateWithoutZone = moment(value).format('YYYY-MM-DD HH:mm');
          var dateWithZone = moment.tz(dateWithoutZone, scope.tickTimezone);

          return dateWithZone;
        }

        return null;
      });
    }
  };
}

'use strict';

/* exported
incrementInputDirective
 */

function incrementInputDirective() {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/directives/increment-input.html',
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      var min = iAttrs.min;

      ngModelCtrl.$formatters.push(function(modelValue) {
        return {number: modelValue};
      });

      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue.number;
      });

      ngModelCtrl.$render = function() {
        scope.number = ngModelCtrl.$viewValue.number;
      };

      ngModelCtrl.$validators.min = function(modelValue, viewValue) {
        var value = modelValue || viewValue;

        return !min || value >= parseInt(min, 10);
      };

      scope.increment = function() {
        ngModelCtrl.$setTouched();
        scope.number++;
      };

      scope.decrement = function() {
        ngModelCtrl.$setTouched();
        scope.number--;
      };

      scope.$watch('number', function(number) {
        ngModelCtrl.$setViewValue({number: number});
      });
    }
  };
}

'use strict';

/* exported
priceInputDirective
 */

function priceInputDirective(CurrenciesService) {
  return {
    restrict: 'AE',
    templateUrl: 'views/directives/price-input.html',
    scope: {
      price: '=',
      disableCost: '@',
      disableMarkup: '@',
      hideCurrency: '@',
      priceStyleRow: '@',
      disableSetByMarkup: '@',
    },
    link: function (scope) {
      scope.conversionRate = CurrenciesService.rates[scope.price.supplierCurrency];

      scope.$watch('price.costInSupplierCurrency + price.amountInSupplierCurrency + price.isAmountByMarkup + price.markup', function (){

        if (scope.price.isAmountByMarkup){
          scope.price.amountInSupplierCurrency = CurrenciesService.convertToFixed(scope.price.costInSupplierCurrency * ((scope.price.markup + 100 )/ 100));
        } else {
          var tempMarkup = 0;
          if (scope.price.costInSupplierCurrency === 0){
            tempMarkup = 0;
          } else {
            tempMarkup = (((scope.price.amountInSupplierCurrency - scope.price.costInSupplierCurrency) / scope.price.costInSupplierCurrency) * 100);
          }
          if (tempMarkup === Infinity || tempMarkup === -Infinity || !tempMarkup){
            tempMarkup = 0;
          }

          scope.price.markup = CurrenciesService.convertToFixed(tempMarkup);
        }

        //hack to render
        scope.price.amountInSupplierCurrency = (scope.price.amountInSupplierCurrency * 1) || 0;
        scope.price.costInSupplierCurrency = (scope.price.costInSupplierCurrency * 1) || 0;
        scope.price.markup = (scope.price.markup * 1) || 0;
        scope.price.amount = CurrenciesService.convertWithFixedPrice(scope.price.amountInSupplierCurrency, scope.price.supplierCurrency, 'USD');
        scope.price.cost = CurrenciesService.convertWithFixedPrice(scope.price.costInSupplierCurrency, scope.price.supplierCurrency, 'USD');
        scope.price.isAmountByMarkup = scope.price.isAmountByMarkup || false;
        scope.price.supplierCurrency = scope.price.supplierCurrency + '';

      });
    }
  };
}

'use strict';

/* exported
priceInputDirective
 */

function linePriceInputDirective(CurrenciesService, LineEditService) {
  return {
    restrict: 'AE',
    templateUrl: 'views/directives/line-price-input.html',
    scope: {
      price: '=',
      line: '=',
      disableCost: '@',
      disableMarkup: '@',
    },
    link: function (scope) {
      scope.conversionRate = CurrenciesService.rates[scope.price.supplierCurrency];

      scope.$watch('price.costInSupplierCurrency + price.amountInSupplierCurrency + price.isAmountByMarkup + price.markup', function (){
        scope.price = LineEditService.changePrice(scope.price, CurrenciesService);
      });
    }
  };
}

'use strict';

/* exported
durationInputDirective
 */

function durationInputDirective(moment) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/directives/duration-input.html',
    scope: {
      user: '=user'
    },
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      ngModelCtrl.$formatters.push(function(modelValue) {
        var duration = moment.duration(modelValue);

        return {
          hours: Math.floor(duration.asHours()),
          minutes: duration.minutes()
        };
      });

      ngModelCtrl.$parsers.push(function(viewValue) {
        return moment.duration({
          hours: viewValue.hours,
          minutes: viewValue.minutes
        });
      });

      ngModelCtrl.$render = function() {
        scope.hours = ngModelCtrl.$viewValue.hours;
        scope.minutes = ngModelCtrl.$viewValue.minutes;
      };

      scope.$watch('hours + minutes', function() {
        ngModelCtrl.$setViewValue({hours: scope.hours, minutes: scope.minutes});
      });
    }
  };
}

'use strict';

/* exported
durationDayInputDirective
 */

function durationDayInputDirective(moment) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/directives/duration-day-input.html',
    scope: {
      user: '=user'
    },
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      ngModelCtrl.$formatters.push(function(modelValue) {
        var duration = moment.duration(modelValue);

        return {
          days: duration.asDays()
        };
      });

      ngModelCtrl.$parsers.push(function(viewValue) {
        if (viewValue.days === 0) {
          return null;
        }
        return moment.duration({
          days: viewValue.days,
        });
      });

      ngModelCtrl.$render = function() {
        scope.days = ngModelCtrl.$viewValue.days;
      };

      scope.$watch('days', function() {
        ngModelCtrl.$setViewValue({days: scope.days});
      });
    }
  };
}

'use strict';

/* exported
fileOnChangeDirective
 */

function fileOnChangeDirective() {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var onChangeHandler = scope.$eval(attrs.fileOnChange);
      element.bind('change', onChangeHandler);
    }
  };
}

'use strict';

/* exported
blockSpaceDirective
 */

function blockSpaceDirective() {
  return {
    restrict: 'A',
    link: function(scope, element) {
      element.bind('keydown', function(event) {
        if (event.which === 32) {
          event.preventDefault();
        }
      });
    }
  };
}

'use strict';
/* exported
googlePlacesAutocompleteDirective
 */

/* global google */
function googlePlacesAutocompleteDirective() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, model) {
      var options = {
        types: [],
        componentRestrictions: {country: ''}
      };
      scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

      google.maps.event.addListener(scope.gPlace, 'place_changed', function() {
        scope.$apply(function() {
          model.$setViewValue(element.val());
        });
      });
    }
  };
}

'use strict';

/* exported
multiStationsDirective
 */


function multiStationsDirective($uibModal, $rootScope, LineEditService, CurrenciesService, appConfig) {
  const icons =  {
    'airport':'fa fa-plane',
    'area':'fa fa-crop'
  }
  return {
    restrict: 'AE',
    templateUrl: 'views/directives/multi-stations.html',
    scope: {
      multiStations: '=',
      label: '@',
      fromStation: '=',
      toStation: '=',
      hideAddStation: '@',
      hideEditStation: '@'
    },
    link: function (scope) {
      scope.isNewPickupDropOff = appConfig.newPickupDropOff;
      scope.openAddEditStationModal = function (station, index,stationType) {
        var stationCopy;
        if (station){
          stationCopy = _.cloneDeep(station);
        }
        const primeryDropOffOffset =  scope.multiStations.find((station)=>station.isPrimary && station.isDropoff).timeOffsetInMinutes
        const isPrivateTransfer = LineEditService.line.privateTransfer && LineEditService.line.privateTransfer.isActivated;
        $uibModal
          .open({
            templateUrl: 'views/lines/add-multi-station.html',
            controller: 'AddMultiStationCtrl',
            resolve: {
              station: function() {
              return stationCopy;
              },
              hideEditStation: function(){
                return !!(scope.hideEditStation === 'true' || scope.hideEditStation === true);
              },
              isPrivateTransfer: isPrivateTransfer,
              supplierCurrency: function() {
                return LineEditService.line.supplierCurrency;
              },            
              stationType: function() {
                return stationType;
              },
              primeryDropOffOffset: function() {
                return primeryDropOffOffset;
              },
              defaultMarkup: function() {
                return LineEditService.line.defaultPrice.markup;
              },
              appConfig: function(){
                return appConfig
              }
            }
          })
          .result
          .then(function(response) {
            if (response && response.station) {
              //validation

              const foundStation = scope.multiStations.find(function(existingStation,existingIndex){
                return (index === undefined || index === null || index !== existingIndex) && existingStation.stationId._id === response.station.stationId._id;
              });

              if (foundStation){
                scope.$emit('notify', {type: 'error', title: 'add station', message: 'station already exists'});
                return;
              }

              //primary station override:
              if (response.station.stationId._id === scope.fromStation._id){
                response.station.isBoarding = true;
                response.station.timeOffsetInMinutes = 0;
              }

              if (response.station.stationId._id === scope.toStation._id){
                if (response.station.timeOffsetInMinutes > 0) {
                  response.station.isDropoff = true;
                } else {
                  scope.$emit('notify', {type: 'error', title: 'add station', message: 'primary station offset must be bigger then 0'});
                  return;
                }
              }



              if (station){
                scope.multiStations[index] = response.station;
              } else {
                scope.multiStations.push(response.station);
              }
              const sortByOffset = (a,b)=>{
                var result = a.timeOffsetInMinutes - b.timeOffsetInMinutes;
                if (b.stationId._id === scope.toStation._id){
                  result = result - 1;
                }
                return result;
              }
              const optionalPickup = scope.multiStations.filter((station)=>station.stationType ==='pickupDropOff' && station.timeOffsetInMinutes <=0).sort(sortByOffset)
              const optionalDropOff = scope.multiStations.filter((station)=>station.stationType ==='pickupDropOff' && station.timeOffsetInMinutes >= primeryDropOffOffset).sort(sortByOffset)
              const stopsAndPrimarys = scope.multiStations.filter((station)=>station.stationType !=='pickupDropOff').sort(sortByOffset)
              const newStationsOrder = [...optionalPickup,...stopsAndPrimarys,...optionalDropOff];
              scope.multiStations = newStationsOrder;
            }
            $rootScope.$broadcast('multi-stations-changed');
          });
      };

      scope.deleteMultiStation = function (index) {
        scope.multiStations.splice(index, 1);
      };

      scope.openEditHotelListModal = function (station, index) {
        var stationCopy;
        if (station){
          stationCopy = _.cloneDeep(station);
        }
        $uibModal
        .open({
          templateUrl: 'views/lines/edit-hotel-list.html',
          controller: 'EditHotelListCtrl',
          resolve: {
            station: function() {
            return stationCopy;
            },
          }
        })
        .result
        .then(function(response) {

          if (!response || !response.station) {
            return;
          }
          scope.multiStations[index] = response.station;
        })
      };
      scope.getTypeIconClassName = function(geometricType, locationType){
        if(locationType){
          return icons[locationType];
        }
        return icons[geometricType];
      }
      scope.getUSDCost = function(price){
        return CurrenciesService.convertWithFixedPrice(price.costInSupplierCurrency, price.supplierCurrency, 'USD')
      }
      scope.showAddPickupDropOff = function(){
        const isPrimaryPickupHasLocationOrAreaType = scope.multiStations.find((station)=>station.isPrimary && station.isBoarding && (station.stationId.type === 'area' || station.stationId.locationType === 'airport'))
        const isPrimaryDropOffHasLocationOrAreaType = scope.multiStations.find((station)=>station.isPrimary && station.isDropoff && (station.stationId.type === 'area' || station.stationId.locationType === 'airport'))
        return !(isPrimaryPickupHasLocationOrAreaType && isPrimaryDropOffHasLocationOrAreaType)
      }
    }
  };
}

'use strict';

/* exported
multiStationsTimeOffsetDirective
 */


function multiStationsTimeOffsetDirective(moment) {
  return {
    restrict: 'AE',
    templateUrl: 'views/directives/multi-stations-time-offset.html',
    scope: {
      timeOffsetInMinutes: '='
    },
    link: function(scope) {

      var debounce = function(cb) {
        var timeout = null;
        return function(newValue, oldValue) {
          if (timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(function() {
            cb(newValue, oldValue);
          }, 600);
        };
      };


      scope.time = moment().startOf('day').add(scope.timeOffsetInMinutes, 'minutes');
      scope.$watch('timeOffsetInMinutes', function(newValue, oldValue){
        if (newValue !== oldValue){
          scope.time = moment().startOf('day').add(newValue, 'minutes');
        }
      });

      scope.$watch('time', debounce(function (newValue, oldValue){
        if (newValue !== null){
          if (!oldValue || newValue && newValue.toString() !== oldValue.toString()){
            scope.time = moment(newValue);
            scope.timeOffsetInMinutes = moment.duration(scope.time.clone().diff(scope.time.clone().startOf('day'))).asMinutes();
          }
        }
      }));
    }
  };
}



"use strict";

/* exported
editPriceDirective
 */

function editPriceDirective(CurrenciesService) {
  return {
    restrict: "AE",
    templateUrl: "views/directives/edit-price.html",
    scope: {
      price: "=",
    },
    link: function (scope) {
      scope.model = {
        price: scope.price,
      };
      
      scope.currenciesService = CurrenciesService;
    },
  };
}

/**
 * Created by john on 28/10/16.
 */
"use strict";

/* exported
 inputPassengerExtraInfoDirective
  */

function priceRulesTable($uibModal, LineEditService, CurrenciesService) {
  return {
    restrict: "AE",
    scope: {
      hideDefaultPrice: "=",
      rules: "=",
      disableEditingDefaultPrice: "=",
      disableEditing: "=",
      level: "@",
      handleEditRule: '=',
    },
    templateUrl: "views/directives/price-rules-table.html",
    link: function (scope, iElement, iAttrs, ctrls) {
      scope.lineEditService = LineEditService;
      scope.isPrivateTransfer = LineEditService.line.privateTransfer && LineEditService.line.privateTransfer.isActivated;
      scope.currenciesService = CurrenciesService;
      scope.supplierCurrency = LineEditService.line.supplierCurrency;

      scope.onEditRule = function (rule) {
        scope.handleEditRule(rule);
      };

      scope.allowEditingRule = function (rule) {
        return (
          (!scope.disableEditing &&
            scope.level !== "eventLevel" &&
            scope.level !== "tripLevel") ||
          (!scope.disableEditing && rule.departureTime && rule.departureTime.length)
        );
      };

      scope.openEditDefaultPriceModal = function () {
        $uibModal
          .open({
            templateUrl: "views/lines/edit-price.html",
            controller: "EditPriceCtrl",
            resolve: {
              title: [
                function () {
                  return "Edit Default Price";
                },
              ],
              price: [
                function () {
                  return scope.lineEditService.line.defaultPrice;
                },
              ],
              lineEditService: [
                function () {
                  return scope.lineEditService;
                }
              ]
            },
          })
          .result.then(function (result) {
            if (result.apply && result.price) {
              scope.lineEditService.line.defaultPrice = result.price;
            }
          });
      };
    },
  };
}

/**
 * Created by john on 28/10/16.
 */
"use strict";

/* exported
  addPriceRulesDirective
   */

function addPriceRulesDirective(
  $rootScope,
  moment,
  CurrenciesService,
  LineEditService,
  uuid,
  _
) {
  return {
    restrict: "AE",
    scope: {
      fixedRulesParams: "=",
      ruleToEdit: "=",
      event: "=",
    },
    templateUrl: "views/directives/add-price-rules.html",
    link: function (scope, iElement, iAttrs, ctrls) {
      const lineEditService = LineEditService;
      scope.isPrivateTransfer = lineEditService.line.privateTransfer.isActivated;
      LineEditService.getEventsDepartureTimesOrIntervals();

      const transformStationsArray = (stations) => {
        const stationIds = new Set();
        return stations.reduce((acc, station) => {
          if (!stationIds.has(station.stationId._id) && station.stationType !== 'pickupDropOff') {
            stationIds.add(station.stationId._id);
            acc.push({ id: station.stationId._id, name: station.stationId.name });
          }
          return acc;
        }, []);
      };
      
      scope.options = {
        fromStationsOptions: transformStationsArray(
          lineEditService.line.aToBMultiStations.concat(lineEditService.line.bToAMultiStations)
        ),
        toStationsOptions: transformStationsArray(
          lineEditService.line.bToAMultiStations.concat(lineEditService.line.aToBMultiStations)
        ),
        departureTimeOptions: (LineEditService.availableDepartureTimes && Object.values(LineEditService.availableDepartureTimes)) || [],
        timeIntervalsOptions: LineEditService.availableIntervals || [],
        dowsOptions: [
          { id: 7, label: "Select all" },
          { id: 0, label: "Sun" },
          { id: 1, label: "Mon" },
          { id: 2, label: "Tue" },
          { id: 3, label: "Wed" },
          { id: 4, label: "Thu" },
          { id: 5, label: "Fri" },
          { id: 6, label: "Sat" },
        ],
        passengerTypesOptions: lineEditService.line.company.passengerTypes,
      };

      scope.setDows = function(el) {
        if (el.id === 7) {
          scope.model.dows = scope.options.dowsOptions.slice(1, 8);
        }
      }

      scope.model = {
        ruleToEdit: scope.ruleToEdit,
        lineEditService,
        price: scope.ruleToEdit
          ? Object.assign(
              {},
              Object.assign(scope.ruleToEdit.price, {
                isAmountByMarkup:
                  lineEditService.line.defaultPrice.isAmountByMarkup,
              })
            )
          : Object.assign({}, lineEditService.line.defaultPrice),
        start:
          scope.ruleToEdit && scope.ruleToEdit.start
            ? moment(scope.ruleToEdit.start).toDate()
            : scope.fixedRulesParams && scope.fixedRulesParams.start
            ? moment(scope.fixedRulesParams.start).toDate()
            : "",
        end:
          scope.ruleToEdit && scope.ruleToEdit.end
            ? moment(scope.ruleToEdit.end).toDate()
            : scope.fixedRulesParams && scope.fixedRulesParams.end
            ? moment(scope.fixedRulesParams.end).toDate()
            : "",
        departureTime: scope.ruleToEdit
          ? scope.ruleToEdit.departureTime
          : scope.fixedRulesParams && scope.fixedRulesParams.time
          ? scope.fixedRulesParams.time
          : [],
        timeIntervals: scope.ruleToEdit
          ? scope.ruleToEdit.timeIntervals
          : scope.fixedRulesParams && scope.fixedRulesParams.timeIntervals
          ? scope.fixedRulesParams.timeIntervals.map((interval) => {
            return Object.assign(interval, { label: `${interval.start}-${interval.end}`})
          })
          : [],
        passengerTypes: scope.ruleToEdit ? scope.ruleToEdit.passengerTypes : [],
        dows: scope.ruleToEdit ? scope.ruleToEdit.dows : [],
        toStations: scope.ruleToEdit
          ? scope.ruleToEdit.toStations
          : scope.fixedRulesParams && scope.fixedRulesParams.toStations
          ? scope.fixedRulesParams.toStations.map((st) => ({
              id: st._id,
              name: st.name,
            }))
          : [],
        fromStations: scope.ruleToEdit
          ? scope.ruleToEdit.fromStations
          : scope.fixedRulesParams && scope.fixedRulesParams.fromStations
          ? scope.fixedRulesParams.fromStations.map((st) => ({
              id: st._id,
              name: st.name,
            }))
          : [],
      };

      scope.$watch(
        "model.price.costInSupplierCurrency + model.price.amountInSupplierCurrency + model.price.isAmountByMarkup + model.price.markup",
        function () {
          scope.model.price = LineEditService.changePrice(
            scope.model.price,
            CurrenciesService
          );
        }
      );

      scope.$watch(
        "model.start + model.end",
        function () {
          scope.model.start = scope.model.start ? moment(scope.model.start).startOf('day').format('YYYY-MM-DD') : '';
          scope.model.end = scope.model.end ? moment(scope.model.end).endOf('day').format('YYYY-MM-DD') : '';
        }
      );

      function validateAndShowErrors(newRule) {
        let isValid = true;
        if (
          newRule.start &&
          newRule.end &&
          moment(newRule.start).isAfter(newRule.end)
        ) {
          return {
            message: '"From Date" cannot be after "To Date"',
            isValid: false,
          };
        }
        if (
          scope.model.price.amountInSupplierCurrency ===
          lineEditService.line.defaultPrice.amountInSupplierCurrency
        ) {
          return {
            message: "The price has to be different than the default price",
            isValid: false,
          };
        }
        let overlappingExistingRules = null;
        if (
          lineEditService.line.priceExceptions &&
          lineEditService.line.priceExceptions.length > 0
        ) {
          lineEditService.line.priceExceptions.forEach(function (existingRule) {
            // skip the rule that we are editing
            if (newRule._id && newRule._id === existingRule._id) {
              return;
            }
            if (lineEditService.isSamePriceRules(existingRule, newRule)) {
              isValid = false;
            }
          });
          if (!isValid) {
            return {
              message: "Identical rule already exists",
              isValid,
            };
          } else {
            // check for overlapping rules
            overlappingExistingRules =
              lineEditService.line.priceExceptions.filter(function (
                existingRule
              ) {
                if (existingRule._id === newRule._id) return;
                return lineEditService.isSamePriceRules(
                  existingRule,
                  newRule,
                  true
                );
              });
          }
        }
        return {
          isValid,
          overlappingExistingRules,
        };
      }

      scope.save = function () {
        const newRule = {
          price: scope.model.price,
          passengerTypes: scope.model.passengerTypes,
          fromStations: scope.model.fromStations,
          toStations: scope.model.toStations,
          start: scope.model.start,
          end: scope.model.end,
          dows: scope.model.dows,
          departureTime: scope.model.departureTime || [],
          timeIntervals: scope.model.timeIntervals,
          _id: scope.ruleToEdit ? scope.ruleToEdit._id : uuid.v4(),
        };

        const validationRes = validateAndShowErrors(newRule);

        if (!validationRes.isValid) {
          $rootScope.$emit("notify", {
            type: "error",
            title: "Validation Error",
            message: validationRes.message,
          });
          return;
        }
        if (scope.ruleToEdit) {
          lineEditService.saveEditedPriceRule(scope.ruleToEdit, {
            apply: validationRes.isValid,
            rule: newRule,
            overlappingExistingRules: validationRes.overlappingExistingRules,
          });
        } else {
          lineEditService.savePriceRule({
            apply: validationRes.isValid,
            rule: newRule,
            overlappingExistingRules: validationRes.overlappingExistingRules,
            event: scope.event,
          });
        }
        $rootScope.$broadcast("closeModal");
      };

      scope.close = function () {
        $rootScope.$broadcast("closeModal");
      };
    },
  };
}

'use strict';

/*
exported
channelsSelectorDirective
 */

function channelsSelectorDirective(Restangular) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/channels/channels-selector.directive.html',
    scope: {
      ngModel: '=ngModel',
      showLabel: '=showLabel',
      label: '@',
    },
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      // Hack for ui-select to update model
      scope.selected = {};
      scope.displayLabel = scope.showLabel === undefined ? true : scope.showLabel;
      var query = { excludeRestrictedChannels: true };
      Restangular
        .all('v2/channels')
        .getList(query)
        .then(function(channels) {
          scope.channels = channels;
        });

      // End of init
      ngModelCtrl.$validators.required = function(modelValue) {
        return !!(modelValue && modelValue._id);
      };

      // From model to view
      ngModelCtrl.$formatters.push(function(modelValue) {
        return {channel: modelValue};
      });

      // From view to model
      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue.channel
          ;
      });

      // Render view
      ngModelCtrl.$render = function() {
        if (ngModelCtrl.$viewValue.channel && !ngModelCtrl.$viewValue.channel.name) {
          var channel = ngModelCtrl.$viewValue.channel;

          Restangular
            .one('v2/channels', channel._id)
            .get()
            .then(function(channel) {
              scope.selected.channel = channel;
            })
            .catch(function() {
              scope.selected.channel = null;
            });
        } else {
          scope.selected.channel = ngModelCtrl.$viewValue.channel;
        }
      };

      // Update model
      scope.updateChannel = function(channel) {
        ngModelCtrl.$setViewValue({channel: channel});
      };

      // Refresh channels list on search
      scope.refreshChannelsList = function(search) {
        if (!search) {
          return;
        }

        var refreshQuery = {search: search, excludeRestrictedChannels: true };
        Restangular
          .all('v2/channels')
          .getList(refreshQuery)
          .then(function(channels) {
            scope.channels = channels;
          });
      };
    }
  };
}

'use strict';

/*
exported
channelsTypeSelectorDirective
 */

function channelsTypeSelectorDirective() {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/channels/channels-type-selector.directive.html',
    scope: {
      ngModel: '=ngModel',
      showLabel: '=showLabel',
      label: '@',
    },
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      // Hack for ui-select to update model
      scope.selected = {};
      scope.displayLabel = scope.showLabel === undefined ? true : scope.showLabel;
      scope.channelTypes = [
        {
          name: 'B2B',
          slug: 'b2b'
        },
        {
          name: 'B2B Redirect',
          slug: 'b2b-redirect'
        },
        {
          name: 'Whitelabel',
          slug: 'whitelabel'
        },
        {
          name: 'Organic',
          slug: 'organic',
        },
        {
          name: 'Organic social',
          slug: 'organic-social'
        },
        {
          name: 'Paid',
          slug: 'paid'
        },
        {
          name: 'Emails',
          slug: 'emails'
        },
        {
          name: 'Referrals',
          slug: 'referrals'
        },
        {
          name: 'Offline',
          slug: 'offline'
        },
        {
          name: 'Group',
          slug: 'group',
        },
        {
          name: 'Affiliate',
          slug: 'affiliate'
        },
        {
          name: 'Booking Agent',
          slug: 'bookingAgent'
        }
      ];

      // End of init
      ngModelCtrl.$validators.required = function(modelValue) {
        return !!(modelValue);
      };

      // From model to view
      ngModelCtrl.$formatters.push(function(modelValue) {
        return {channelType: modelValue};
      });

      // From view to model
      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue.channelType;
      });

      // Render view
      ngModelCtrl.$render = function() {
        scope.selected.channelType = ngModelCtrl.$viewValue.channelType;
      };

      // Update model
      scope.updateType = function(type) {
        ngModelCtrl.$setViewValue({channelType: type});
      };
    }
  };
}
